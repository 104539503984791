import React from 'react';

import Asset_Icon_GitHub from '../assets/Icon_GitHub_Night.png';
import Asset_Icon_GitHub_Actions from '../assets/Icon_GitHub_Actions.png';
import Asset_Icon_Ruff from '../assets/Icon_Ruff.png';
import Asset_Icon_ESLint from '../assets/Icon_ESLint.png';
import Asset_Icon_pytest from '../assets/Icon_pytest.png';
import Asset_Icon_Jest from '../assets/Icon_Jest.png';
import Asset_Icon_Codecov from '../assets/Icon_Codecov.png';
import Asset_Icon_GCC from '../assets/Icon_GCC.png';
import Asset_Icon_CPP from '../assets/Icon_CPP.png';
import Asset_Icon_Qt from '../assets/Icon_Qt.png';
import Asset_Icon_SQL from '../assets/Icon_SQL.png';
import Asset_Icon_PostgreSQL from '../assets/Icon_SQL_PostgreSQL.png';
import Asset_Icon_MySQL from '../assets/Icon_SQL_MySQL.png';
import Asset_Icon_SQL_MSSQLServer from '../assets/Icon_SQL_MSSQLServer.png';
import Asset_Icon_MATLAB from '../assets/Icon_MATLAB.png';
import Asset_Icon_Python from '../assets/Icon_Python.png';
import Asset_Icon_Python_pandas from '../assets/Icon_Python_pandas.png';
import Asset_Icon_Python_NumPy from '../assets/Icon_Python_NumPy.png';
import Asset_Icon_Python_PyTorch from '../assets/Icon_Python_PyTorch.png';
import Asset_Icon_Python_TensorFlow from '../assets/Icon_Python_TensorFlow.png';
import Asset_Icon_Flask from '../assets/Icon_Flask_Night.png';
import Asset_Icon_HTML5 from '../assets/Icon_HTML5.png';
import Asset_Icon_CSS3 from '../assets/Icon_CSS3.png';
import Asset_Icon_PHP from '../assets/Icon_PHP.png';
import Asset_Icon_VisualBasic from '../assets/Icon_VisualBasic.png';
import Asset_Icon_JavaScript from '../assets/Icon_JavaScript.png';
import Asset_Icon_NodeJS from '../assets/Icon_NodeJS.png';
import Asset_Icon_Express from '../assets/Icon_Express_Night.png';
import Asset_Icon_Axios from '../assets/Icon_Axios.png';
import Asset_Icon_npm from '../assets/Icon_npm.png';
import Asset_Icon_ReactJS from '../assets/Icon_ReactJS.png';
import Asset_Icon_TailwindCSS from '../assets/Icon_TailwindCSS.png';
import Asset_Icon_NET from '../assets/Icon_NET.png';
import Asset_Icon_LaTeX from '../assets/Icon_LaTeX_Night.png';
import Asset_Icon_typst from '../assets/Icon_typst_Night.png';
import Asset_Icon_Wireshark from '../assets/Icon_Wireshark.png';
import Asset_Icon_IDAPro from '../assets/Icon_IDAPro.png';
import Asset_Icon_SonarQube from '../assets/Icon_SonarQube.png';
import Asset_Icon_UML from '../assets/Icon_UML.png';
import Asset_Icon_StarUML from '../assets/Icon_StarUML.png';
import Asset_Icon_FileZilla from '../assets/Icon_FileZilla.png';
import Asset_Icon_Docker from '../assets/Icon_Docker.png';
import Asset_Icon_Anaconda from '../assets/Icon_Anaconda.png';
import Asset_Icon_VSC from '../assets/Icon_VSC.png';
import Asset_Icon_Jupyter from '../assets/Icon_Jupyter.png';
import Asset_Icon_MSWord from '../assets/Icon_MSWord.png';
import Asset_Icon_MSExcel from '../assets/Icon_MSExcel.png';
import Asset_Icon_llamacpp from '../assets/Icon_llamacpp.png';
import Asset_Icon_ollama from '../assets/Icon_ollama_Night.png';
import Asset_Icon_LMStudio from '../assets/Icon_LMStudio.png';
import Asset_Icon_HuggingFace from '../assets/Icon_HuggingFace.png';
import Asset_Icon_openAI from '../assets/Icon_openAI_Night.png';
import Asset_Icon_JSON from '../assets/Icon_JSON.png';



function Experience() {
    return (
        <div id="experience"className="grid gap-8 items-start justify-center h-content font-semibold subpixel-antialiased mb-16 min-w-ld max-w-6xl mx-4 sm:mx-4 md:mx-auto">
            <div id="skillstable" className="mt-32 backdrop-blur-sm bg-black/70 rounded-3xl px-6 py-8 mx-auto">
                <p className="text-center text-3xl sm:text-4xl transition hover:scale-90 duration-500">Experience & Education:</p>
            </div>
        <div id="experiencetable" className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start justify-center h-content font-semibold subpixel-antialiased max-w-5xl sm:mx-4 md:mx-auto">
            <div id="timeline" className="backdrop-blur-sm bg-black/80 rounded-3xl p-8 sm:p-16 md:p-16 order-2 md:order-1">
                <div id="20241" className="text-left">
                    <h1 className="text-4xl transition hover:scale-90 duration-500 bg-clip-text text-transparent bg-gradient-to-r from-sky-600 to-sky-900">LLMs Researcher</h1>
                    <p className="flex justify-between text-xl"><span className="transition hover:scale-90 duration-500">Thesis Internship</span><span className="hidden sm:contents md:contents">May 2024 - Jun 2024</span></p>
                        <p className="text-xl contents sm:hidden md:hidden">May 2024 - Jun 2024</p>
                    <h2 className="text-3xl transform hover:origin-left hover:scale-125 duration-700">@<a href="https://www.zucchetti.com/" target="_blank" className="bg-clip-text text-transparent bg-gradient-to-r from-sky-600 to-sky-900 hover:from-sky-400 hover:to-sky-700">Zucchetti</a></h2>
                    <div className="my-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(36px, 1fr))', gap: '1rem' }}>
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_GitHub} alt="GitHub" title="GitHub" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_SonarQube} alt="SonarQube" title="SonarQube" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_HuggingFace} alt="HuggingFace" title="HuggingFace" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_llamacpp} alt="llama.cpp" title="llama.cpp" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_LMStudio} alt="LM Studio" title="LM Studio" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_openAI} alt="openAI APIs" title="openAI APIs" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_JSON} alt="JSON" title="JSON" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_VSC} alt="Visual Studio Code" title="Visual Studio Code" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python} alt="Python" title="Python" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        
                    </div>
                    <p className="sm:text-lg md:text-lg tracking-tighter">
                        <ul className="list-disc">
                            <li className="transition hover:scale-90 duration-500">Researched small-sized LLMs capabilities;</li>
                            <li className="transition hover:scale-90 duration-500">Implemented a LLM-base Practical Solution to assist Static Analysis;</li>
                            <li className="transition hover:scale-90 duration-500">Wrote my Thesis.</li>
                        </ul>
                    </p>
                </div>
                <hr className="border-gray-300 my-8 mx-auto w-full max-w-2xl" />
                <div id="20232" className="mt-8 text-left">
                    <h1 className="text-4xl transition hover:scale-90 duration-500 bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-800">Software Engineer</h1>
                    <p className="flex justify-between text-xl"><span className="transition hover:scale-90 duration-500">University Project</span><span className="hidden sm:contents md:contents">Oct 2023 - Apr 2024</span></p>
                        <p className="text-xl contents sm:hidden md:hidden">Oct 2023 - Apr 2024</p>
                    <h2 className="text-3xl transform hover:origin-left hover:scale-125 duration-700">@<a href="https://www.ergon.it/" target="_blank" className="bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-800 hover:from-green-400 hover:to-green-600">Ergon Informatica</a></h2>
                    <div className="my-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(36px, 1fr))', gap: '1rem' }}>
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_GitHub} alt="GitHub" title="GitHub" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_GitHub_Actions} alt="GitHub Actions" title="GitHub Actions" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Ruff} alt="Ruff" title="Ruff" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_ESLint} alt="ESLint" title="ESLint" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_pytest} alt="pytest" title="pytest" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Jest} alt="Jest" title="Jest" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Codecov} alt="Codecov" title="Codecov" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_UML} alt="UML" title="UML" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_StarUML} alt="StarUML" title="StarUML" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Docker} alt="Docker" title="Docker" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_VSC} alt="Visual Studio Code" title="Visual Studio Code" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_SQL} alt="SQL" title="SQL" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_MySQL} alt="MySQL" title="MySQL" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Anaconda} alt="Anaconda" title="Anaconda" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python} alt="Python" title="Python" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python_pandas} alt="pandas" title="pandas" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python_NumPy} alt="NumPy" title="NumPy" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python_PyTorch} alt="PyTorch" title="PyTorch" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Flask} alt="Flask" title="Flask" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_HTML5} alt="HTML5" title="HTML5" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_CSS3} alt="CSS3" title="CSS3" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_JavaScript} alt="JavaScript" title="JavaScript" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_NodeJS} alt="NodeJS" title="NodeJS" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_npm} alt="npm" title="npm" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_ReactJS} alt="ReactJS" title="ReactJS" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_TailwindCSS} alt="TailwindCSS" title="TailwindCSS" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Axios} alt="Axios" title="Axios" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Express} alt="Express" title="Express" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_JSON} alt="JSON" title="JSON" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_LaTeX} alt="LaTeX" title="LaTeX" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_typst} alt="typst" title="typst" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                    </div>
                    <p className="sm:text-lg md:text-lg tracking-tighter">
                        <ul className="list-disc">
                            <li className="transition hover:scale-90 duration-500">Learned Software Engineering By Doing It;</li>
                            <li className="transition hover:scale-90 duration-500">Closely interacted with Stakeholders;</li>
                            <li className="transition hover:scale-90 duration-500">Implemented and Applied DevOps;</li>
                            <li className="transition hover:scale-90 duration-500">Produced Neural Network based Recommender Web App PoC & MVP through RTB & PB phases.</li>
                        </ul>
                    </p>
                </div>
                <hr className="border-gray-300 my-8 mx-auto w-full max-w-2xl" />
                <div id="20231" className="mt-8 text-left">
                    <h1 className="text-4xl transition hover:scale-90 duration-500 bg-clip-text text-transparent bg-gradient-to-r from-orange-600 to-orange-900">Software Developer</h1>
                    <p className="flex justify-between text-xl"><span className="transition hover:scale-90 duration-500">Seasonal Job</span><span className="hidden sm:contents md:contents">Mar 2023 - May 2023</span></p>
                        <p className="text-xl contents sm:hidden md:hidden">Mar 2023 - May 2023</p>
                    <h2 className="text-3xl transform hover:origin-left hover:scale-125 duration-700">@<a href="https://www.tiesseinformatica.it/" target="_blank" className="bg-clip-text text-transparent bg-gradient-to-r from-orange-600 to-orange-900 hover:from-orange-500 hover:to-orange-700">Tiesse Informatica</a></h2>
                    <div className="my-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(36px, 1fr))', gap: '1rem' }}>
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_MSWord} alt="Microsoft Word" title="Microsoft Word" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_MSExcel} alt="Microsoft Excel" title="Microsoft Excel" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_VSC} alt="Visual Studio Code" title="Visual Studio Code" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_NET} alt=".NET" title=".NET" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_VisualBasic} alt="Visual Basic" title="Visual Basic" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_SQL} alt="SQL" title="SQL" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_SQL_MSSQLServer} alt="Microsoft SQL Server" title="Microsoft SQL Server" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_FileZilla} alt="FileZilla" title="FileZilla" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python} alt="Python" title="Python" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python_pandas} alt="pandas" title="pandas" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                    </div>
                    <p className="sm:text-lg md:text-lg tracking-tighter">
                        <ul className="list-disc">
                            <li className="transition hover:scale-90 duration-500">Improved ERP functionalities and security;</li>
                            <li className="transition hover:scale-90 duration-500">Advised on State of the Art Technologies.</li>
                        </ul>
                    </p>
                </div>
            </div>
            <div className="flex flex-col space-y-8 order-1 md:order-2">
                <div id="education" className="backdrop-blur-sm bg-black/80 rounded-3xl p-8 md:p-16 sm:p-16">
                    <div id="UniversityBSc" className="text-left">
                        <h1 className="text-3xl transition hover:scale-90 duration-500 bg-clip-text text-transparent bg-gradient-to-r from-red-700 to-red-900">Bachelor of Science in Computer Science</h1>
                        <p className="text-xl transform hover:origin-left hover:scale-125 duration-700">@<a href="https://www.unipd.it/en/" target="_blank" className="bg-clip-text text-transparent bg-gradient-to-r from-red-700 to-red-900 hover:from-red-600 hover:to-red-700">Università <span className="hidden sm:contents md:contents">degli Studi </span>di Padova</a></p>
                        <p className="flex justify-between text-xl"><span className="transition hover:scale-90 duration-500">(University of Padua)</span><span className="hidden sm:contents md:contents">2019 - 2024</span></p>
                        <p className="text-xl contents sm:hidden md:hidden">2019 - 2024</p>
                        <div className="my-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(36px, 1fr))', gap: '1rem' }}>
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_GCC} alt="GCC" title="GCC" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_VSC} alt="Visual Studio Code"  title="Visual Studio Code" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_CPP} alt="CPP" title="CPP" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Qt} alt="Qt" title="Qt" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_SQL} alt="SQL" title="SQL" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_PostgreSQL} alt="PostgreSQL" title="PostgreSQL" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_MySQL} alt="MySQL" title="MySQL" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_JSON} alt="JSON" title="JSON" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_MATLAB} alt="MATLAB" title="MATLAB" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_HTML5} alt="HTML5" title="HTML5" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_CSS3} alt="CSS3" title="CSS3" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_PHP} alt="PHP" title="PHP" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_JavaScript} alt="JavaScript" title="JavaScript" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_FileZilla} alt="FileZilla" title="FileZilla" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Jupyter} alt="Jupyter" title="Jupyter" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python} alt="Python" title="Python" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python_pandas} alt="pandas" title="pandas" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python_NumPy} alt="NumPy" title="NumPy" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python_TensorFlow} alt="TensorFlow" title="TensorFlow" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Wireshark} alt="Wireshark" title="Wireshark" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_IDAPro} alt="IDA Pro" title="IDA Pro" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_MSExcel} alt="Microsoft Excel" title="Microsoft Excel" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_GitHub} alt="GitHub" title="GitHub" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_StarUML} alt="StarUML" title="StarUML" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_UML} alt="UML" title="UML" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        </div>
                    </div>
                    <hr className="border-gray-300 my-8 mx-auto w-full max-w-2xl" />
                    <div id="HighSchool" className="mt-8 text-left">
                        <h1 className="text-3xl transition hover:scale-90 duration-500 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-cyan-400">Scientific High School Diploma, Applied Sciences</h1>
                        <p className="text-xl transform hover:origin-left hover:scale-125 duration-700 ">@<a href="https://www.liceofermipadova.edu.it/" target="_blank" className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-cyan-400 hover:from-blue-400 hover:to-cyan-300">Liceo Scientifico Enrico Fermi</a></p>
                        <p className="flex justify-between text-xl"><span className="transition hover:scale-90 duration-500">di Padova</span><span className="hidden sm:contents md:contents">2014 - 2019</span></p>
                        <p className="text-xl contents sm:hidden md:hidden">2014 - 2019</p>
                        <div className="mt-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(36px, 1fr))', gap: '1rem' }}>
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_MSWord} alt="Microsoft Word" title="Microsoft Word" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_MSExcel} alt="Microsoft Excel" title="Microsoft Excel" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_CPP} alt="CPP" title="CPP" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_HTML5} alt="HTML5" title="HTML5" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_CSS3} alt="CSS3" title="CSS3" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_JavaScript} alt="JavaScript" title="JavaScript" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_SQL} alt="SQL" title="SQL" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        </div>
                    </div>
                </div>
                <div id="certificates" className="backdrop-blur-sm bg-black/80 rounded-3xl p-8 md:p-16 hidden md:grid">
                    <div id="Zucchetti1" className="text-left">
                        <h1 className="text-3xl transform hover:scale-90 duration-700 bg-clip-text text-transparent bg-gradient-to-r from-sky-600 to-sky-900">Health and Safety in the Workplace, Low Risk Level</h1>
                        <p className="transition hover:scale-90 duration-500">CERTIFICATE OF ATTENDANCE</p>
                        <p className="flex justify-between text-xl"><span className="transform hover:origin-left hover:scale-125 duration-700">@<a href="https://www.zucchetti.com/" target="_blank" className="bg-clip-text text-transparent bg-gradient-to-r from-sky-600 to-sky-900 hover:from-sky-400 hover:to-sky-700">Zucchetti</a></span><span>May 2023</span></p>
                    </div>
                    <hr className="border-gray-300 my-8 mx-auto w-full max-w-2xl" />
                    <div id="Udemy1" className="text-left">
                        <h1 className="text-3xl transform hover:origin-left hover:scale-110 duration-700 bg-clip-text text-transparent bg-gradient-to-r from-violet-600 to-violet-800 hover:from-violet-500 hover:to-violet-700"><a href="https://udemy-certificate.s3.amazonaws.com/image/UC-ce2697ba-7b39-4828-9f07-9a21202affcd.jpg?v=1698341762000" target="_blank">Artificial Intelligence A-Z</a></h1>
                        <p className="transition hover:scale-90 duration-500">CERTIFICATE OF COMPLETION</p>
                        <p className="flex justify-between text-xl"><span className="transform hover:origin-left hover:scale-125 duration-700">@<a href="https://www.udemy.com/" target="_blank" className="bg-clip-text text-transparent bg-gradient-to-r from-violet-600 to-violet-800 hover:from-violet-500 hover:to-violet-700">Udemy</a></span><span>October 2023</span></p>
                        <div className="mt-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(36px, 1fr))', gap: '1rem' }}>
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Anaconda} alt="Anaconda" title="Anaconda" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Jupyter} alt="Jupyter" title="Jupyter" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python} alt="Python" title="Python" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python_NumPy} alt="NumPy" title="NumPy" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python_PyTorch} alt="PyTorch" title="PyTorch" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        </div>
                    </div>
                    <hr className="border-gray-300 my-8 mx-auto w-full max-w-2xl" />
                    <div id="Tiesse1" className="text-left">
                        <h1 className="text-3xl transform hover:scale-90 duration-700 bg-clip-text text-transparent bg-gradient-to-r from-orange-600 to-orange-900">Data Protection and Information Security</h1>
                        <p className="transition hover:scale-90 duration-500">CERTIFICATE OF ATTENDANCE</p>
                        <p className="flex justify-between text-xl"><span className="transform hover:origin-left hover:scale-125 duration-700">@<a href="https://www.tiesseinformatica.it/" target="_blank" className="bg-clip-text text-transparent bg-gradient-to-r from-orange-600 to-orange-900 hover:from-orange-500 hover:to-orange-700">Tiesse Informatica</a></span><span>April 2023</span></p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Experience;