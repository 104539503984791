import React from 'react';

import Asset_Icon_Protonmail from '../assets/Icon_Protonmail.png';
import Asset_Icon_LinkedIn from '../assets/Icon_LinkedIn.png';
import Asset_Icon_GitHub from '../assets/Icon_GitHub_Night.png';

function Footer() {
    return (
        <div className="m-8 mt-32 text-center text-gray-100">
            <p>
                Minimal Personal Showcase/Portfolio single-page Website. {/* Link to Repository: 
                <button className="transform hover:scale-125 transition duration-500 bg-transparent hover:bg-white/10 sm:hover:bg-transparent md:hover:bg-transparent saturate-100 hover:saturate-150 opacity-80 hover:opacity-100 px-2 rounded-2xl"><a href="https://github.com/ShameIRL/Project-MinimalWebPortfolio" target="_blank"><img src={Asset_Icon_GitHub} alt="GitHub" type="image/png" width="14" height="14" /></a></button>
                    */}
            </p>
            <p>
                © 2024 Matteo Baggio. Contact me:
                <button className="transform hover:scale-125 transition duration-500 bg-transparent hover:bg-white/10 sm:hover:bg-transparent md:hover:bg-transparent saturate-100 hover:saturate-150 opacity-80 hover:opacity-100 pl-2 pr-1 rounded-2xl"><a href="mailto:morningape@protonmail.com" target="_blank"><img src={Asset_Icon_Protonmail} alt="Protonmail" type="image/png" width="14" height="14" /></a></button>
                <button className="transform hover:scale-125 transition duration-500 bg-transparent hover:bg-white/10 sm:hover:bg-transparent md:hover:bg-transparent saturate-100 hover:saturate-150 px-1 rounded-2xl"><a href="https://it.linkedin.com/in/baggio-matteo" target="_blank"><img src={Asset_Icon_LinkedIn} alt="LinkedIn" type="image/png" width="14" height="14" /></a></button>
                <button className="transform hover:scale-125 transition duration-500 bg-transparent hover:bg-white/10 sm:hover:bg-transparent md:hover:bg-transparent saturate-100 hover:saturate-150 opacity-80 hover:opacity-100 px-1 rounded-2xl"><a href="https://github.com/ShameIRL" target="_blank"><img src={Asset_Icon_GitHub} alt="GitHub" type="image/png" width="14" height="14" /></a></button>
            </p>
        </div>
    )
}

export default Footer;