import React from 'react';

import Animation_Unblur from '../animations/unblurIcon_intro.js';
import Animation_TypeWrite from '../animations/typewrite_intro.js';

import Asset_Icon_Protonmail from '../assets/Icon_Protonmail.png';
import Asset_Icon_LinkedIn from '../assets/Icon_LinkedIn.png';
import Asset_Icon_GitHub from '../assets/Icon_GitHub_Night.png';

function Intro() {
    return (
        <div id="intro" className="font-semibold subpixel-antialiased text-xl sm:text-3xl md:text-3xl tracking-wide sm:tracking-wider md:tracking-wider flex items-center justify-center h-screen flex-col text-center">
            <p><Animation_TypeWrite text="Hello, I'm " start ={500} delay={35} /><span className="text-3xl sm:text-5xl md:text-5xl"><Animation_TypeWrite text="Matteo." start ={1100} delay={55} /></span></p>
            <p className="mt-10"><Animation_TypeWrite text="I'm a " start ={2400} delay={35} /><span className="text-3xl sm:text-5xl md:text-5xl"><Animation_TypeWrite text="Software Developer." start ={2700} delay={50} /></span></p>
            <p className="mt-10"><Animation_TypeWrite text="Feel free to " start ={4500} delay={40} /><span className="text-3xl sm:text-5xl md:text-5xl"><Animation_TypeWrite text="look around" start ={5100} delay={60} /></span><Animation_TypeWrite text=" and get to " start ={5800} delay={45} /><span className="text-3xl sm:text-5xl md:text-5xl"><Animation_TypeWrite text="know me." start ={6400} delay={60} /></span></p>
            <p className="mt-10"><Animation_TypeWrite text="You can " start ={7600} delay={35} /><span className="text-3xl sm:text-5xl md:text-5xl"><Animation_TypeWrite text="contact me" start ={7900} delay={75} /></span><Animation_TypeWrite text=" here:" start ={8700} delay={40} /></p>
            <p className="mt-10 space-x-10 flex">
                <Animation_Unblur start={9000} delay={100}><button className="transform hover:scale-125 transition duration-500 bg-transparent hover:bg-white/10 sm:hover:bg-transparent md:hover:bg-transparent saturate-100 hover:saturate-150 opacity-80 hover:opacity-100 py-2 px-4 rounded-2xl"><a href="mailto:morningape@protonmail.com" target="_blank"><img src={Asset_Icon_Protonmail} alt="Protonmail" title="Protonmail" type="image/png" width="48" height="48" /></a></button></Animation_Unblur>
                <Animation_Unblur start={9300} delay={100}><button className="transform hover:scale-125 transition duration-500 bg-transparent hover:bg-white/10 sm:hover:bg-transparent md:hover:bg-transparent saturate-100 hover:saturate-150 py-2 px-4 rounded-2xl"><a href="https://it.linkedin.com/in/baggio-matteo" target="_blank"><img src={Asset_Icon_LinkedIn} alt="LinkedIn" title="LinkedIn" type="image/png" width="48" height="48" /></a></button></Animation_Unblur>
                <Animation_Unblur start={9600} delay={100}><button className="transform hover:scale-125 transition duration-500 bg-transparent hover:bg-white/10 sm:hover:bg-transparent md:hover:bg-transparent saturate-100 hover:saturate-150 opacity-80 hover:opacity-100 py-2 px-4 rounded-2xl"><a href="https://github.com/ShameIRL" target="_blank"><img src={Asset_Icon_GitHub} alt="GitHub" title="GitHub" type="image/png" width="42" height="48" /></a></button></Animation_Unblur>
            </p>
        </div>
    )
}

export default Intro;