import React from 'react';
import { Link } from 'react-scroll';

import Asset_Icon_Home from '../assets/Flaticon_Home_Night.png';
import Asset_Icon_Skill from '../assets/Flaticon_Gear_Night.png';
import Asset_Icon_Experience from '../assets/Flaticon_Briefcase_Night.png';
import Asset_Icon_Projects from '../assets/Icon_Protonmail.png';
import Asset_Icon_About from '../assets/Flaticon_About_Night.png';

function Navbar() {
    return (
        <nav id="navbar" className="backdrop-blur-sm bg-white/10 fixed top-8 left-6 right-6 mx-auto z-50 flex justify-around p-4 rounded-full max-w-sm">
            <Link to="intro" spy={true} smooth={true} duration={500} className="cursor-pointer transform hover:scale-125 transition duration-500 hover:saturate-150 flex flex-col items-center opacity-90 hover:opacity-100" offset={0}>
                <img src={Asset_Icon_Home} alt="Home" title="Home" width="36" height="36" />
            </Link>
            <Link to="experience" spy={true} smooth={true} duration={500} className="cursor-pointer transform hover:scale-125 transition duration-500 hover:saturate-150 flex flex-col items-center opacity-90 hover:opacity-100" offset={0}>
                <img src={Asset_Icon_Experience} alt="Experience" title="Eperience" width="36" height="36" />
            </Link>
            <Link to="skills" spy={true} smooth={true} duration={500} className="cursor-pointer transform hover:scale-125 transition duration-500 hover:saturate-150 flex flex-col items-center opacity-90 hover:opacity-100" offset={0}>
                <img src={Asset_Icon_Skill} alt="Skills" title="Skills" width="36" height="36" />
            </Link>
            {/*<Link to="projects" spy={true} smooth={true} duration={500} className="cursor-pointer transform hover:scale-125 transition duration-500 hover:saturate-150 flex flex-col items-center opacity-90 hover:opacity-100" offset={0}>
                <img src={Asset_Icon_Projects} alt="Projects" width="36" height="36" />
            </Link>*/}
            <Link to="about" spy={true} smooth={true} duration={500} className="cursor-pointer transform hover:scale-125 transition duration-500 hover:saturate-150 flex flex-col items-center opacity-90 hover:opacity-100" offset={0}>
                <img src={Asset_Icon_About} alt="About" title="About Me" width="36" height="36" />
            </Link>
        </nav>
    )
}

export default Navbar;