import React, { useState, useEffect } from 'react';

const Typewriter = ({ text, start, delay }) => {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timeSinceLoad = performance.now();
        const initialDelay = Math.max(start - timeSinceLoad, 0);
    
        const timeout = setTimeout(() => {
          if (currentIndex < text.length) {
            setCurrentText(prevText => prevText + text[currentIndex]);
            setCurrentIndex(prevIndex => prevIndex + 1);
          }
        }, initialDelay > 0 ? initialDelay : delay);
    
        return () => clearTimeout(timeout);
      }, [currentIndex, delay, text, start]);

    return <span>{currentText}</span>;
};

export default Typewriter;