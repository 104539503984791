import React from 'react';

import Asset_Icon_Windows from '../assets/Icon_Windows.png';
import Asset_Icon_macOS from '../assets/Icon_macOS_Night.png';
import Asset_Icon_GitHub from '../assets/Icon_GitHub_Night.png';
import Asset_Icon_GitHub_Actions from '../assets/Icon_GitHub_Actions.png';
import Asset_Icon_Ruff from '../assets/Icon_Ruff.png';
import Asset_Icon_ESLint from '../assets/Icon_ESLint.png';
import Asset_Icon_pytest from '../assets/Icon_pytest.png';
import Asset_Icon_Jest from '../assets/Icon_Jest.png';
import Asset_Icon_Codecov from '../assets/Icon_Codecov.png';
import Asset_Icon_GCC from '../assets/Icon_GCC.png';
import Asset_Icon_CPP from '../assets/Icon_CPP.png';
import Asset_Icon_Qt from '../assets/Icon_Qt.png';
import Asset_Icon_SQL from '../assets/Icon_SQL.png';
import Asset_Icon_PostgreSQL from '../assets/Icon_SQL_PostgreSQL.png';
import Asset_Icon_MySQL from '../assets/Icon_SQL_MySQL.png';
import Asset_Icon_SQL_MSSQLServer from '../assets/Icon_SQL_MSSQLServer.png';
import Asset_Icon_MATLAB from '../assets/Icon_MATLAB.png';
import Asset_Icon_Python from '../assets/Icon_Python.png';
import Asset_Icon_Python_pandas from '../assets/Icon_Python_pandas.png';
import Asset_Icon_Python_NumPy from '../assets/Icon_Python_NumPy.png';
import Asset_Icon_Python_PyTorch from '../assets/Icon_Python_PyTorch.png';
import Asset_Icon_Python_TensorFlow from '../assets/Icon_Python_TensorFlow.png';
import Asset_Icon_Flask from '../assets/Icon_Flask_Night.png';
import Asset_Icon_HTML5 from '../assets/Icon_HTML5.png';
import Asset_Icon_CSS3 from '../assets/Icon_CSS3.png';
import Asset_Icon_PHP from '../assets/Icon_PHP.png';
import Asset_Icon_VisualBasic from '../assets/Icon_VisualBasic.png';
import Asset_Icon_JavaScript from '../assets/Icon_JavaScript.png';
import Asset_Icon_NodeJS from '../assets/Icon_NodeJS.png';
import Asset_Icon_Express from '../assets/Icon_Express_Night.png';
import Asset_Icon_Axios from '../assets/Icon_Axios.png';
import Asset_Icon_npm from '../assets/Icon_npm.png';
import Asset_Icon_ReactJS from '../assets/Icon_ReactJS.png';
import Asset_Icon_TailwindCSS from '../assets/Icon_TailwindCSS.png';
import Asset_Icon_NET from '../assets/Icon_NET.png';
import Asset_Icon_LaTeX from '../assets/Icon_LaTeX_Night.png';
import Asset_Icon_TeX from '../assets/Icon_TeX_Night.png';
import Asset_Icon_typst from '../assets/Icon_typst_Night.png';
import Asset_Icon_Wireshark from '../assets/Icon_Wireshark.png';
import Asset_Icon_IDAPro from '../assets/Icon_IDAPro.png';
import Asset_Icon_SonarQube from '../assets/Icon_SonarQube.png';
import Asset_Icon_UML from '../assets/Icon_UML.png';
import Asset_Icon_StarUML from '../assets/Icon_StarUML.png';
import Asset_Icon_FileZilla from '../assets/Icon_FileZilla.png';
import Asset_Icon_Docker from '../assets/Icon_Docker.png';
import Asset_Icon_Anaconda from '../assets/Icon_Anaconda.png';
import Asset_Icon_VSC from '../assets/Icon_VSC.png';
import Asset_Icon_Jupyter from '../assets/Icon_Jupyter.png';
import Asset_Icon_Xcode from '../assets/Icon_Xcode.png';
import Asset_Icon_MSWord from '../assets/Icon_MSWord.png';
import Asset_Icon_MSExcel from '../assets/Icon_MSExcel.png';
import Asset_Icon_ollama from '../assets/Icon_ollama_Night.png';
import Asset_Icon_llamacpp from '../assets/Icon_llamacpp.png';
import Asset_Icon_LMStudio from '../assets/Icon_LMStudio.png';
import Asset_Icon_Figma from '../assets/Icon_Figma.png';
import Asset_Icon_Swift from '../assets/Icon_Swift.png';
import Asset_Icon_Blender from '../assets/Icon_Blender.png';
import Asset_Icon_JSON from '../assets/Icon_JSON.png';

{/* ABOVE ARE ALL ICON IMPORTS / BELOW ARE ALL IMPORTED ICONS / MANY FOUND HERE: https://techicons.dev/
<img src={Asset_Icon_Windows} alt="Windows" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_macOS} alt="macOS" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_GitHub} alt="GitHub" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_GitHub_Actions} alt="GitHub Actions" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Ruff} alt="Ruff" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_ESLint} alt="ESLint" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_pytest} alt="pytest" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Jest} alt="Jest" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Codecov} alt="Codecov" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_GCC} alt="GCC" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_CPP} alt="CPP" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Qt} alt="Qt" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_SQL} alt="SQL" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_PostgreSQL} alt="PostgreSQL" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_MySQL} alt="MySQL" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_SQL_MSSQLServer} alt="Microsoft SQL Server" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_FileZilla} alt="FileZilla" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_HTML5} alt="HTML5" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_CSS3} alt="CSS3" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_PHP} alt="PHP" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_JavaScript} alt="JavaScript" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_NodeJS} alt="NodeJS" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Express} alt="Express" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Azios} alt="Azios" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_npm} alt="npm" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_ReactJS} alt="ReactJS" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_TailwindCSS} alt="TailwindCSS" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Python} alt="Python" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_MATLAB} alt="MATLAB" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Python_pandas} alt="pandas" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Python_NumPy} alt="NumPy" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Python_PyTorch} alt="PyTorch" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Python_TensorFlow} alt="TensorFlow" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Flask} alt="Flask" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_LaTeX} alt="LaTeX" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_TeX} alt="TeX" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_typst} alt="typst" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Wireshark} alt="Wireshark" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_IDAPro} alt="IDA Pro" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_SonarQube} alt="SonarQube" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_UML} alt="UML" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_StarUML} alt="StarUML" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Docker} alt="Docker" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Anaconda} alt="Anaconda" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Xcode} alt="Xcode" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Jupyter} alt="Jupyter" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_VSC} alt="VSC" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_VisualBasic} alt="Visual Basic" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_NET} alt=".NET" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_MSWord} alt="Microsoft Word" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_MSExcel} alt="Microsoft Excel" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_ollama} alt="ollama" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Figma} alt="Figma" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Swift} alt="Swift" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
<img src={Asset_Icon_Blender} alt="Blender" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
*/}

function Skills() {
    return (
        <div id="skills"className="grid gap-8 items-start justify-center h-content font-semibold subpixel-antialiased mb-16 min-w-ld max-w-6xl mx-4 sm:mx-4 md:mx-auto">
            <div id="skillstable" className="mt-32 backdrop-blur-sm bg-black/70 rounded-3xl px-6 py-8 mx-auto">
                <p className="text-center text-3xl sm:text-4xl transition hover:scale-90 duration-500">Computer Science Skills:</p>
            </div>
            <div id="skillstable" className="backdrop-blur-sm bg-black/80 rounded-3xl p-8 sm:p-16 md:p-16">
                <div className="sm:grid sm:grid-cols-2 md:grid md:grid-cols-2">
                    <div className="sm:pr-8 md:pr-8">
                        <h1 className="text-3xl mb-4 transition hover:scale-90 duration-500">Languages</h1>
                        <div className="my-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(36px, 1fr))', gap: '1rem' }}>
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_CPP} alt="CPP" title="CPP" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python} alt="Python" title="Python" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_JavaScript} alt="JavaScript" title="JavaScript" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_HTML5} alt="HTML5" title="HTML5" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_CSS3} alt="CSS3" title="CSS3" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_VisualBasic} alt="Visual Basic" title="Visual Basic" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        </div>
                        <hr className="border-gray-300 my-8 mx-auto w-full max-w-2xl" />
                        <h1 className="text-3xl mb-4 transition hover:scale-90 duration-500">Tools</h1>
                        <div className="my-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(36px, 1fr))', gap: '1rem' }}>
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_GitHub} alt="GitHub" title="GitHub" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_VSC} alt="Visual Studio Code" title="Visual Studio Code" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Anaconda} alt="Anaconda" title="Anaconda" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Docker} alt="Docker" title="Docker" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_LMStudio} alt="LM Studio" title="LM Studio" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_FileZilla} alt="FileZilla" title="FileZilla" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        </div>
                        <hr className="border-gray-300 my-8 mx-auto w-full max-w-2xl" />
                        <h1 className="text-3xl mb-4 transition hover:scale-90 duration-500">Documents</h1>
                        <div className="my-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(36px, 1fr))', gap: '1rem' }}>
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_LaTeX} alt="LaTeX" title="LaTeX" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_typst} alt="typst" title="typst" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_MSWord} alt="Microsoft Word" title="Microsoft Word" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        </div>
                    </div>
                    <div class="hidden sm:grid md:grid absolute inset-y-0 my-8 left-1/2 w-px bg-gray-400"></div>
                    <hr className="grid sm:hidden md:hidden border-gray-300 my-8 mx-auto w-full max-w-2xl" />
                    <div className="sm:pl-8 md:pl-8">
                        <h1 className="text-3xl mb-4 transition hover:scale-90 duration-500">Libraries & Frameworks</h1>
                        <div className="my-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(36px, 1fr))', gap: '1rem' }}>
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Qt} alt="Qt" title="Qt" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python_pandas} alt="pandas" title="pandas" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_Python_PyTorch} alt="PyTorch" title="PyTorch" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_llamacpp} alt="llama.cpp" title="llama.cpp" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_ReactJS} alt="ReactJS" title="ReactJS" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_TailwindCSS} alt="TailwindCSS" title="TailwindCSS" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        </div>
                        <hr className="border-gray-300 my-8 mx-auto w-full max-w-2xl" />
                        <h1 className="text-3xl mb-4 transition hover:scale-90 duration-500">Data</h1>
                        <div className="my-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(36px, 1fr))', gap: '1rem' }}>
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_SQL} alt="SQL" title="SQL" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_MySQL} alt="MySQL" title="MySQL" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_SQL_MSSQLServer} alt="Microsoft SQL Server" title="Microsoft SQL Server" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_JSON} alt="JSON" title="JSON" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_MSExcel} alt="Microsoft Excel" title="Microsoft Excel" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        </div>
                        <hr className="border-gray-300 my-8 mx-auto w-full max-w-2xl" />
                        <h1 className="text-3xl mb-4 transition hover:scale-90 duration-500">Design</h1>
                        <div className="my-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(36px, 1fr))', gap: '1rem' }}>
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_UML} alt="UML" title="UML" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Icon_StarUML} alt="StarUML" title="StarUML" style={{ width: "36px", height: "36px", objectFit: "contain" }}  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills;