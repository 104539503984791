import React from 'react';
import Animation_Background from './animations/background_video.js';
import Navbar from './components/navbar.js';
import Intro from './components/intro.js';
import Skills from './components/skills.js';
import Experience from './components/experience.js';
import Projects from './components/projects.js';
import About from './components/about.js';
import Footer from './components/footer.js';


function App() {
  return (
    <div className="App relative text-gray-200">
      <Animation_Background />
      <div className="relative z-10">
        <Navbar />
        <Intro />
        <Experience />
        <Skills />
        {/*<Projects />*/}
        <About />
        <Footer />
      </div>
    </div>
  );
}

export default App;