import React from 'react';

import Asset_Flag_Italy from '../assets/Flag_Italy.png';
import Asset_Flag_Austria from '../assets/Flag_Austria.png';
import Asset_Flag_Germany from '../assets/Flag_Germany.png';
import Asset_Flag_Slovenia from '../assets/Flag_Slovenia.png';
import Asset_Flag_Croatia from '../assets/Flag_Croatia.png';
import Asset_Flag_GreatBritain from '../assets/Flag_GreatBritain.png';
import Asset_Flag_Vatican from '../assets/Flag_Vatican.png';

import Asset_Flag_SanMarino from '../assets/Flag_SanMarino.png';
import Asset_Flag_Netherlands from '../assets/Flag_Netherlands.png';
import Asset_Flag_Belgium from '../assets/Flag_Belgium.png';
import Asset_Flag_Switzerland from '../assets/Flag_Switzerland.png';
import Asset_Flag_Luxembourg from '../assets/Flag_Luxembourg.png';
import Asset_Flag_France from '../assets/Flag_France.png';
import Asset_Flag_Liechtenstein from '../assets/Flag_Liechtenstein.png';
import Asset_Flag_Ireland from '../assets/Flag_Ireland.png';
import Asset_Flag_Denmark from '../assets/Flag_Denmark.png';
import Asset_Flag_Poland from '../assets/Flag_Poland.png';
import Asset_Flag_Sweden from '../assets/Flag_Sweden.png';
import Asset_Flag_CzechRepublic from '../assets/Flag_CzechRepublic.png';

import Asset_Icon_Discogs from '../assets/Logo_Discogs_Night.png';

function About() {
    return (
        <div id="about" className="grid items-start justify-center h-content font-semibold subpixel-antialiased max-w-5xl mx-4 sm:mx-4 md:mx-auto">
            <div id="aboutMe" className="mt-32 backdrop-blur-sm bg-black/80 rounded-3xl p-8 sm:p-12 md:p-12">
                <div className="leading-normal">
                        <h1 className="text-4xl text-center md:text-5xl transition hover:scale-90 duration-500">Who am I?</h1>
                        <p className="text-justify mt-8 text-base sm:text-xl md:text-xl">
                            I'm Matteo. 
                            I am a curious guy that tries to learn new things everyday.
                            I think of myself as a creative, but logical, person.
                            I love patterns and finding problems from every kind of domain to objectively fix them in my brain.
                            In the world of Computer Science I found the right environment where I can successfully apply my innate abilities while constantly learning new things.
                            Lately I've grown interest in Artificial Intelligence and its applications.
                            I thrive in environments where I can constantly put my mind at work to solve problems or to create something, especially if there is a chance to discuss and collaborate with like-minded people.
                        </p>
                        <p className="text-center mt-4 text-base text-xl transition hover:scale-90 duration-500">More Stuff about me:</p> 
                </div>
            </div>
            <div id="hobbies" className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-8 justify-center h-content font-semibold subpixel-antialiased max-w-5xl mx-4 sm:mx-4 md:mx-auto">
                <div id="hobbies1" className="flex flex-col space-y-8 order-1 md:order-1">
                    <div id="licenses" className="backdrop-blur-sm bg-black/80 rounded-3xl p-8">
                        <h1 className="text-3xl text-center md:text-4xl transition hover:scale-90 duration-500">Licenses</h1>
                        <p className="bg-clip-text text-transparent bg-gradient-to-r from-pink-200 to-pink-300 text-xl md:text-2xl mt-4 text-center transition hover:scale-90 duration-500">Driver's License</p>
                        <p className="text-base text-right flex justify-between"><span>Oct 2019 - Jan 2030</span><span className="transition hover:scale-90 duration-500">B & AM</span></p>
                        <p className="bg-clip-text text-transparent bg-gradient-to-r from-amber-700 to-amber-950 text-xl md:text-2xl mt-4 text-center transition hover:scale-90 duration-500">Passport</p>
                        <p className="text-base text-center">Mar 2024 - Mar 2034</p>
                        <p className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-100 to-yellow-300 text-xl md:text-2xl mt-4 text-center transition hover:scale-90 duration-500">Shooting License</p>
                        <p className="text-base text-right flex justify-between"><span>Jun 2024 - Jun 2029</span><span className="transition hover:scale-90 duration-500">Sports Use</span></p>
                    </div>
                    <div id="shooting" className="backdrop-blur-sm bg-black/80 rounded-3xl p-8">
                        <h1 className="text-3xl text-center md:text-4xl transition hover:scale-90 duration-500">Shooting</h1>
                        <p className="mt-4 text-center">Sport and Hobby since I was 10.<br />Multiple Pistol 10m Italian Nationals Qualifications.</p>
                        <p className="mx-8 mt-4 text-center flex justify-between">
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-500 transition hover:scale-90 duration-500">Air Pistol</span>
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-400 to-gray-500 transition hover:scale-90 duration-500">Glock17</span>
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-700 to-orange-900 transition hover:scale-90 duration-500">AKM</span>
                        </p>
                    </div>
                </div>
                <div id="hobbies2" className="flex flex-col space-y-8 order-1 md:order-2">
                    <div id="travelling" className="backdrop-blur-sm bg-black/80 rounded-3xl p-8">
                        <h1 className="text-3xl text-center md:text-4xl transition hover:scale-90 duration-500">Travelling</h1>
                        <p className="mt-4 text-center">Travelled a lot since I was a kid.<br />I love exploring new places, admiring local Architecture & Art and interacting with locals.</p>
                        <div className="mt-4 grid" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(24px, 1fr))', gap: '0.75rem' }}>
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Italy} alt="Italy" title="Italy" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Austria} alt="Austria" title="Austria" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Germany} alt="Germany" title="Germany" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Slovenia} alt="Slovenia" title="Slovenia" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Croatia} alt="Croatia" title="Croatia" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_GreatBritain} alt="Great Britain" title="Great Britain" style={{ width: "24px", height: "20px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Vatican} alt="Vatican" title="Vatican" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_SanMarino} alt="San Marino" title="San Marino" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Netherlands} alt="Netherlands" title="Netherlands" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Belgium} alt="Belgium" title="Belgium" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Switzerland} alt="Switzerland" title="Switzerland" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Luxembourg} alt="Luxembourg" title="Luxembourg" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_France} alt="France" title="France" style={{ width: "24px", height: "20px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Liechtenstein} alt="Liechtenstein" title="Liechtenstein" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Ireland} alt="Ireland" title="Ireland" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Denmark} alt="Denmark" title="Denmark" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Poland} alt="Poland" title="Poland" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_Sweden} alt="Sweden" title="Sweden" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                            <img className="transition hover:scale-125 duration-500" src={Asset_Flag_CzechRepublic} alt="Czech Republic" title="Czech Republic" style={{ width: "24px", height: "24px", objectFit: "contain" }}  />
                        </div>
                    </div>
                    <div id="music" className="backdrop-blur-sm bg-black/80 rounded-3xl p-8">
                        <h1 className="text-3xl text-center md:text-4xl transition hover:scale-90 duration-500">Music</h1>
                        <p className="mt-4 text-center">I love listening to music from different backgrounds.<br />I also have a cool Vinyls collection:</p>
                        <p className="flex justify-center mt-4"><a href = "https://www.discogs.com/user/shame59/collection" target="_blank" className="hover:scale-125 transition duration-500 opacity-90 hover:opacity-100"><img src={Asset_Icon_Discogs} alt="Discogs" title="Discogs" width="80" height="80" /></a></p>
                    </div>
                </div>
                <div id="hobbies3" className="flex flex-col space-y-8 order-1 md:order-3">
                    <div id="licenses" className="backdrop-blur-sm bg-black/80 rounded-3xl p-8">
                        <h1 className="text-3xl text-center md:text-4xl transition hover:scale-90 duration-500">Gaming</h1>
                        <p className="mt-4 text-center">Always enjoyed Competitive Videogames since I was a kid.<br />Played Pokémon VGC and LoL at high and competitive levels.</p>
                    </div>
                    <div id="skiing" className="backdrop-blur-sm bg-black/80 rounded-3xl p-8">
                        <h1 className="text-3xl text-center md:text-4xl transition hover:scale-90 duration-500">Skiing</h1>
                        <p className="mt-4 text-center">Skiied since I could walk.<br />Has always been a fun Seasonal Activity with Family and Friends.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;